<template>
  <div>
    <el-select v-model="searchOptionsValue" placeholder="Выберите вариант поиска" class="search-options">
      <el-option
        v-for="item in searchOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled">
      </el-option>
    </el-select>
    <el-input v-model="value"></el-input>
  </div>
</template>

<script>

import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RosreestrAPI} from '@/core/infrastructure/api/modules/RosreestrAPI'
import MapManager from '@bingo_soft/mapmanager'

export default {
  name: 'enterCoordinates',
  components: { CoordinateSystem },
  props: {
    map: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      searchOptions: [{
        value: 'kn',
        label: 'Кадастровый номер'
      }, {
        value: 'zouit',
        label: 'ЗОУИТ'
      }],
      searchOptionsValue: 'kn',
      value: null,
      cs: 3857,
      coordinates: {
        x: 0,
        y: 0
      }
    }
  },
  methods: {
    changeCS (value) {
      this.$emit('change-cs', value)
    },
    async findByCadastralNumber() {
      this.$emit('loading-show')
      let success = false
      const totalTrying = 10
      let objectInfo = null
      if (this.searchOptionsValue === 'kn') {
        // find area
        for (let currentTrying = 0; currentTrying < totalTrying && !success; currentTrying++) {
          try {
            objectInfo = await APIClient.shared.request(new RosreestrAPI.FindObjectByCadastralNumber(this.value, 1))
          } catch (e) { }
          if (objectInfo !== null) {
            success = true
          }
        }
        if (objectInfo === null || objectInfo?.total === 0) {
          // find building
          success = false
          for (let currentTrying = 0; currentTrying < totalTrying && !success; currentTrying++) {
            try {
              objectInfo = await APIClient.shared.request(new RosreestrAPI.FindObjectByCadastralNumber(this.value, 5))
            } catch (e) { }
            if (objectInfo !== null) {
              success = true
            }
          }
        }
      } else if (this.searchOptionsValue === 'zouit') {
        // find zouit
        for (let currentTrying = 0; currentTrying < totalTrying && !success; currentTrying++) {
          try {
            objectInfo = await APIClient.shared.request(new RosreestrAPI.FindObjectByCadastralNumber(this.value, 10))
          } catch (e) { }
          if (objectInfo !== null) {
            success = true
          }
        }
      }
      if (objectInfo && objectInfo.features.length > 0) {
        const feature = objectInfo.features[0]
        this.coordinates.x = feature.center.x
        this.coordinates.y = feature.center.y
        this.changeCS(this.cs)
        this.$emit('change-coordinates', this.coordinates)
      } else {
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: 'Объект не найден'
        })
      }
      this.$emit('loading-hide')
    }
  }
}
</script>

<style scoped>
  .search-options {
    margin-bottom: 10px;
  }
</style>
