import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace FIASAddressServiceAPI {
  export class GetDaDataInfo implements APIRequest<string> {
    response: string;
    path = '/fiasaddressservice/dadata/fias_data';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;
    params = {};

    constructor (address: string) {
      this.params = [address]
    }
  }
}
