<template>
  <div>
    <coordinate-system
      @change-cs="changeCS"
      :options="userCSs"
    ></coordinate-system>
    <el-input placeholder="X" v-model="coordinates.x" @change="$emit('change-coordinates', coordinates)"></el-input>
    <el-input placeholder="Y" v-model="coordinates.y" @change="$emit('change-coordinates', coordinates)"></el-input>
  </div>
</template>

<script>

import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'enterCoordinates',
  components: { CoordinateSystem },
  props: {
    userCSs: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      coordinates: {
        type: Object,
        default () {
          return { x: 0, y: 0 }
        }
      },
      cs: null
    }
  },
  methods: {
    changeCS (value) {
      this.$emit('change-cs', value)
    }
  }
}
</script>

<style scoped>

</style>
