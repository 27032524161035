import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace RosreestrAPI {
    export class GetObjectByCoordinates implements APIRequest<string> {
        response: string;
        path = '/api/features';
        baseURL = 'https://pkk.rosreestr.ru';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data;
        hideNotification = true;
        constructor (point: Array<Number>, type: number) {
          this.path += `/${type}?sq={"type":"Point","coordinates":[${point[0]}, ${point[1]}]}&tolerance=1&limit=11`
        }
    }
    export class GetObjectInfoByCadastrNumber implements APIRequest<string> {
        response: string;
        path = '/api/features';
        baseURL = 'https://pkk.rosreestr.ru';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data;
        hideNotification = true;
        constructor (cadastrNumber: string, type: number) {
          this.path += `/${type}/${cadastrNumber}`
        }
    }
    export class FindObjectByCadastralNumber implements APIRequest<string> {
        response: string;
        path = '/api/features';
        baseURL = 'https://pkk.rosreestr.ru';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data;
        hideNotification = true;
        constructor (cadastrNumber: string, type: number) {
          this.path += `/${type}?text=${cadastrNumber}&tolerance=1&limit=1`
        }
    }
}
